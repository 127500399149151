<template>
  <div>
    <b-modal
      v-model="showModal"
      ref="modal"
      centered
      size="xl"
      footer-class="pt-0 border-0"
    >
      <template #modal-header="{ close }">
        <div class="d-flex justify-content-between align-items-center w-100">
          <div class="text-center flex-grow-1 text-r-20">ข้อมูลการชำระเงิน</div>
        </div>
        <button type="button" aria-label="Close" class="close" @click="close">
          ×
        </button>
      </template>
      <div class="bg-white border-red">
        <b-row>
          <b-col>
            <b-table
              responsive
              striped
              hover
              :fields="fields"
              :items="items"
              :busy="isBusy"
              show-empty
              empty-text="No matching records found"
              class="table-main"
              :no-border-collapse="false"
              sticky-header
            >
              <template v-slot:cell(name)="data">
                <b>{{ data.item.barcode }}</b>
                <p>{{ data.item.name }}</p>
              </template>
              <template v-slot:cell(unitPrice)="data">
                <div
                  v-if="
                    data.item.promotion_detail &&
                    data.item.promotion_detail.normal_unit_price !=
                      data.item.promotion_detail.promo_unit_price
                  "
                >
                  <p>
                    {{
                      data.item.promotion_detail.net_price_per_item
                        | numeral("0,0.00")
                    }}
                  </p>
                  <p class="normal-unit-price">
                    {{ data.item.price | numeral("0,0.00") }}
                  </p>
                </div>
                <p v-else>{{ data.item.price | numeral("0,0.00") }}</p>
              </template>
              <template v-slot:cell(quantity)="data">
                {{ data.item.quantity | numeral("0,0") }}
              </template>
              <template v-slot:cell(discount)="data">
                <p v-if="data.item.promotion_detail">
                  {{ data.item.promotion_detail.discount | numeral("0,0.00") }}
                </p>
                <p v-else>{{ data.item.discount | numeral("0,0.00") }}</p>
              </template>
              <template v-slot:cell(net)="data">
                <p v-if="data.item.promotion_detail">
                  {{ data.item.promotion_detail.net_price | numeral("0,0.00") }}
                </p>
                <p v-else>{{ data.item.net | numeral("0,0.00") }}</p>
              </template>
              <template v-slot:table-busy>
                <div class="text-center text-black my-2">
                  <b-spinner class="align-middle"></b-spinner>
                  <strong class="ml-2">Loading...</strong>
                </div>
              </template>
            </b-table>
          </b-col>
        </b-row>

        <b-row class="mb-3 align-items-center">
          <template v-for="(pay, index) in payment.payments">
            <b-col md="4">
              <InputSelect
                title="ช่องทางการชำระเงิน"
                isRequired
                :options="paymentList"
                v-model="pay.payment_channel_id"
                @onDataChange="(val) => onDataChange(val, index)"
                class="mb-0"
                :class="{ 'mt-2': index !== 0 }"
                textField="name"
                valueField="id"
                :isValidate="
                  $v.payment.payments.$each[index].payment_channel_id.$error
                "
                :v="$v.payment.payments.$each[index].payment_channel_id"
              >
                <template v-slot:option-first>
                  <b-form-select-option :value="null" disabled
                    >-- ช่องทางการชำระเงิน --</b-form-select-option
                  >
                </template>
              </InputSelect>
            </b-col>
            <b-col md="8">

              <InputText
                :value="pay.format_total_payment"
                textFloat="ยอดชำระ"
                placeholder="ยอดชำระ"
                @onKeypress="handleKeyPress"
                decimalPoint="2"
                @input="(val, e) => limitValue(val, e, pay)"
                class="custom-input"
                :class="{
                  'mt-2': index !== 0,
                  'mb-4':
                    $v.payment.payments.$each[index].payment_channel_id.$error,
                }"
                type="text"
                :maxLength="10"
                name="transfer_amount"
                :isValidate="
                  $v.payment.payments.$each[index].total_payment.$error
                "
                :v="$v.payment.payments.$each[index].total_payment"
              >
                <template v-slot:text>
                  <span
                    class="ml-1 text-primary"
                    v-if="pay.payment_channel_id == 19"
                    >เครดิตคงเหลือ :
                    {{
                      (storeCredit - pay.total_payment) | numeral("0,0.00")
                    }}</span
                  >
                  <u
                    v-if="index !== 0"
                    @click="deletePayment(index)"
                    class="text-desc-message text-danger font-weight-bold pointer"
                    >ลบ
                  </u>
                </template>
              </InputText>
              <!-- <p
                v-if="$v.payment.payments.$each[index].total_payment.$error"
                class="text-error text-right mt-2"
              >
                กรุณากรอกยอดชำระไม่เกิน
                {{ storeCredit | numeral("0,0") }}
              </p> -->
            </b-col>
          </template>

          <b-col cols="12">
            <p
              v-if="$v.payment.amount.$error"
              class="text-error text-right mt-2"
            >
              กรุณากรอกยอดชำระมากกว่าหรือเท่ากับ
              {{ (payment.net - payment.amount) | numeral("0,0") }}
            </p>
            <b-button
              class="add-payment-container text-gray mt-2 text-center py-2 w-100"
              :disabled="payment.payments.length == 5"
              @click="addPayment()"
            >
              <div class="">เพิ่มช่องทางชำระเงิน</div>
            </b-button>
          </b-col>
        </b-row>

        <div class="otp-panel text-m-14" v-if="isVerifyOTP">
          <div class="otp-ref">
            <div>รหัสยืนยัน Ref: {{ formOTP.refCode }}</div>
            <div>{{ formOTP.telephone }}</div>
          </div>
          <div class="otp-form" v-if="!formOTP.isConfirmed">
            <InputText
              textFloat=""
              v-model="formOTP.OTP"
              class="mb-2"
              placeholder="รหัส OTP"
              type="text"
            />
            <div class="text-r-12">
              <div class="otp-action">
                <div
                  v-if="!formOTP.isExpired && !formOTP.isLoading"
                  class="otp-again-text ml-auto"
                >
                  ส่ง OTP อีกครั้งได้ใน {{ formOTP.timeDisplay }}
                </div>

                <b-button
                  v-else
                  variant="link"
                  class="otp-again-button ml-auto text-right"
                  @click="sendOTP"
                  :disabled="formOTP.isLoading"
                  >ส่งรหัส OTP อีกครั้ง</b-button
                >
                <b-button
                  variant="primary-color"
                  class="btn-modal btn-hover text-r-12"
                  :disabled="formOTP.isLoading"
                  @click="verifyOTP"
                >
                  ยืนยัน OTP
                </b-button>
              </div>
            </div>
          </div>
          <div v-else class="otp-form-success">
            <font-awesome-icon icon="check-circle" />
            ยืนยัน OTP สำเร็จ
          </div>
        </div>
        <div>
          <div class="summary-transaction-panel">
            <b-row>
              <b-col> มูลค่ารวม </b-col>
              <b-col class="text-right">
                {{ payment.total | numeral("0,0") }}
              </b-col>
            </b-row>
            <b-row>
              <b-col> ส่วนลด </b-col>
              <b-col class="text-right">
                {{ payment.discount | numeral("0,0.00") }}
              </b-col>
            </b-row>
            <b-row>
              <b-col> รวม </b-col>
              <b-col class="text-right">
                {{ payment.net | numeral("0,0") }}
              </b-col>
            </b-row>
            <b-row>
              <b-col> เงินทอน </b-col>
              <b-col class="text-right">
                {{ change | numeral("0,0.00") }}
              </b-col>
            </b-row>
          </div>
        </div>
      </div>
      <template #modal-footer="{ hide }">
        <div class="d-flex justify-content-between w-100 my-0">
          <b-button
            variant="close"
            class="btn-modal btn-hover w-25 mr-1"
            @click="hide('forget')"
            :disabled="isLoading"
          >
            ยกเลิก
          </b-button>
          <b-button
            variant="primary-color"
            class="btn-modal btn-hover w-25 ml-1"
            @click="confirm"
            :disabled="isLoading"
          >
            ยืนยัน
          </b-button>
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>
import InputText from "@/components/inputs/InputText";
import InputSelect from "@/components/inputs/InputSelect";
import { required } from "vuelidate/lib/validators";
import { maxValue } from "vuelidate/lib/validators";

export default {
  components: {
    InputText,
    InputSelect,
  },
  props: {
    paymentChannelList: {
      type: Array,
      default: [],
    },
    telephone: {
      type: String,
      default: "",
    },
    user_guid: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      isLoading: false,
      showModal: false,
      user: null,
      form: null,
      fields: [
        {
          key: "name",
          label: "รายการ Product List",
          tdClass: "text-left",
          thStyle: { "min-width": "120px" },
        },
        {
          key: "unitPrice",
          label: "มูลค่า Unit Price",
          thStyle: { "min-width": "120px" },
        },
        {
          key: "quantity",
          label: "จำนวน Quantity",
          thStyle: { "min-width": "120px" },
        },
        {
          key: "discount",
          label: "ส่วนลด Discount",
          thStyle: { "min-width": "120px" },
        },
        {
          key: "net",
          label: "ราคาสุทธิ Net.",
          thStyle: { "min-width": "120px" },
        },
      ],
      now: null,
      items: [],
      isBusy: false,
      rows: 0,
      payment: {
        id: 0,
        amount: "",
        total: 0,
        discount: 0,
        net: 0,
        payments: [],
      },
      formOTP: {
        redeemOrderId: 0,
        OTP: "",
        refCode: "",
        telephone: "",
        isExpired: false,
        timeDisplay: "",
        isConfirmed: false,
        isLoading: true,
        timeId: "",
      },
      paymentList: [],
      isVerifyOTP: false,
      storeCredit: 0,
    };
  },
  validations() {
    const min = (value) => {
      return value >= this.payment.net && !!value;
    };

    const checkCredit = (value, payment_channel_id) => {
      if (payment_channel_id == 19) {
        return value <= this.storeCredit;
      } else return true;
    };

    return {
      payment: {
        id: {
          required,
        },
        amount: {
          min,
        },
        payments: {
          $each: {
            payment_channel_id: {
              required,
            },
            total_payment: {
              maxValue(val, { payment_channel_id }) {
                return checkCredit(val, payment_channel_id);
              },
            },
          },
        },
      },
    };
  },
  watch: {
    "payment.payments": {
      handler(newVal) {
        this.payment.amount = newVal
          .reduce((sum, item) => sum + Number(item.total_payment), 0)
          .toString();

        this.payment.id = newVal[0].payment_channel_id;
      },
      deep: true,
    },
  },
  computed: {
    change() {
      let result =
        parseFloat(this.payment.amount) - parseFloat(this.payment.net);
      if (this.payment.amount < this.payment.net) {
        result = 0;
      }
      return result;
    },
    // isVerifyOTP() {
    //   return this.form && this.form.promotion
    //     ? this.form.promotion.some(
    //         promo => promo.promotion_value == 0 && promo.point >= 0
    //       )
    //     : false;
    // }
  },
  methods: {
    clearOTPForm() {
      this.formOTP = {
        redeemOrderId: 0,
        OTP: "",
        refCode: "",
        telephone: "",
        isExpired: false,
        timeDisplay: "",
        isConfirmed: false,
        isLoading: true,
        timeId: this.formOTP.timeId,
      };
      this.isVerifyOTP = false;
    },
    startTimer(duration) {
      const start = Date.now();
      let diff;
      let hours;
      let minutes;
      let seconds;
      // let timerId;
      let t = this;

      function timer() {
        diff = duration - (((Date.now() - start) / 1000) | 0);

        hours = (diff / 3600) | 0;
        minutes = (diff / 60) % 60 | 0;
        seconds = diff % 60 | 0;

        hours = hours < 10 ? `0${hours}` : hours;
        minutes = minutes < 10 ? `0${minutes}` : minutes;
        seconds = seconds < 10 ? `0${seconds}` : seconds;

        t.formOTP.timeDisplay = `${minutes}:${seconds}`;

        if (diff <= 0) {
          clearInterval(t.formOTP.timeId);
          t.formOTP.isExpired = true;
        }
      }
      timer();
      this.formOTP.timeId = setInterval(timer, 1000);
    },
    async show(user, form, transaction) {
      this.$v.payment.$reset();
      clearInterval(this.formOTP.timeId);
      this.clearOTPForm();

      if (form.promotion.find((x) => x.id === 253)) {
        this.isVerifyOTP = true;
      }

      this.user = user;
      this.form = form;
      this.payment.amount = "";
      this.payment.total = transaction.price;
      this.payment.discount = transaction.discount;
      this.payment.net = transaction.netPrice;
      this.items = form.product;
      this.payment.payments = [
        {
          payment_channel_id: null,
          total_payment: null,
          format_total_payment: null,
        },
      ];
      // this.payment.payments[0].payment_channel_id =
      //   this.paymentChannelList[0].id;
      this.paymentList = this.paymentChannelList.map((x) => ({
        ...x,
        disabled: false,
      }));

      if (form.credit) {
        this.items = [
          {
            name: "วงเงินเครดิต",
            price: form.credit.total_price,
            quantity: 1,
            discount: 0,
            net: form.credit.net_price,
          },
        ];

        let creditChannel = this.paymentList.find((i) => i.id == 19);
        if (creditChannel) {
          creditChannel.disabled = true;
        }
      }
      this.formOTP.telephone = this.user.telephone;

      if (this.isVerifyOTP) this.sendOTP();
      this.showModal = true;
    },
    async onDataChange(val, index) {
      let channelIndex = this.paymentList.findIndex((x) => x.id == val);
      if (channelIndex !== -1) {
        this.paymentList[channelIndex].disabled = true;
      }
      if (this.payment.payments[index].payment_channel_id) {
        let selectedIndex = this.paymentList.findIndex(
          (x) => x.id == this.payment.payments[index].payment_channel_id
        );
        if (selectedIndex !== -1) {
          this.paymentList[selectedIndex] = {
            ...this.paymentList[selectedIndex],
            disabled: false,
          };
        }
      }
      this.payment.payments[index].payment_channel_id = val;

      if (val == 19) {
        await this.getStoreCredit();
      }
    },
    async getStoreCredit() {
      const res = await this.axios(
        `${this.$baseUrl}/product/get_store_creditbalance/${this.user_guid}`
      );

      this.storeCredit = res.total_credit;
    },
    hide() {
      this.showModal = false;
    },
    async confirm() {
      if (this.isVerifyOTP && !this.formOTP.isConfirmed) {
        this.warningAlert("กรุณายืนยัน OTP");
        return;
      } else {
        this.$v.payment.$touch();

        if (this.payment.amount < this.payment.net)
          this.warningAlert("ยอดชำระไม่ถูกต้อง");

        if (this.$v.payment.$error) return;
      }
      let isUseCredit = this.payment.payments.find(
        (u) => u.payment_channel_id == 19
      );

      this.$emit("confirm", this.payment, isUseCredit);
      this.hide();
    },
    isNumber: function (evt) {
      // evt = evt ? evt : window.event;
      // var charCode = evt.which ? evt.which : evt.keyCode;
      // if (
      //   charCode > 31 &&
      //   (charCode < 48 || charCode > 57) &&
      //   charCode !== 46
      // ) {
      //   evt.preventDefault();
      // } else {
      //   return true;
      // }
    },
    async sendOTP() {
      this.formOTP.OTP = "";
      this.formOTP.isExpired = false;
      this.formOTP.timeDisplay = "";
      let body = {
        telephone: this.formOTP.telephone,
        redeemOrderId: 0,
      };
      this.formOTP.isLoading = true;
      await this.axios
        .post(`${this.$baseUrl}/redeem/SendOTP`, body)
        .then(async (data) => {
          if (data.result == 1) {
            this.formOTP.refCode = data.detail;
            this.startTimer(60 * 2);
          } else {
            this.warningAlert(data.message);
          }
          this.formOTP.isLoading = false;
        })
        .catch((err) => {
          this.warningAlert(err.message);
        });
    },
    async verifyOTP() {
      let body = {
        telephone: this.formOTP.telephone,
        redeemOrderId: 0,
        refCode: this.formOTP.refCode,
        OTP: this.formOTP.OTP,
      };
      this.formOTP.isLoading = true;
      await this.axios
        .post(`${this.$baseUrl}/redeem/VerifyOTP`, body)
        .then(async (data) => {
          if (data.result == 1) {
            this.formOTP.isConfirmed = true;
          } else {
            this.warningAlert("OTP ไม่ถูกต้อง");
          }
          this.formOTP.isLoading = false;
        })
        .catch((err) => {
          this.warningAlert(err.message);
        });
    },
    addPayment() {
      this.payment.payments.push({
        payment_channel_id: null,
        total_payment: null,
        format_total_payment: null,
      });
    },
    deletePayment(index) {
      let channelIndex = this.paymentList.findIndex(
        (x) => x.id == this.payment.payments[index].payment_channel_id
      );
      if (channelIndex !== -1) {
        this.paymentList[channelIndex].disabled = false;
      }
      this.payment.payments.splice(index, 1);
    },
    handleKeyPress(event) {
      const char = event.key;
      if (!/[\d.,]/.test(char)) {
        event.preventDefault();
      }
      if (
        (char === "." && event.target.value.includes(".")) ||
        (char === "," && event.target.value.includes(","))
      ) {
        event.preventDefault();
      }
    },
    limitValue(val, e, pay) {
      let value = val.replace(/[^0-9.]/g, "");
      if (value === "") {
        pay.total_payment = value;
        pay.format_total_payment = value;
        return;
      }

      let numericValue = parseFloat(value);

      const formattedValue = Number(value).toLocaleString("en-US", {
        maximumFractionDigits: 2,
      });
      pay.total_payment = numericValue;
      pay.format_total_payment = formattedValue;
      if (
        pay.total_payment > this.storeCredit &&
        pay.payment_channel_id == 19
      ) {
        pay.total_payment = this.storeCredit;
        pay.format_total_payment = this.storeCredit.toString();
        e.target.value = this.storeCredit.toString();
        console.log(pay.format_total_payment);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.otp-panel {
  padding: 1rem 0;
  border-top: 1px solid #eee;
  border-bottom: 1px solid #d0c5c5;
  margin-bottom: 12px;
  display: flex;
  justify-content: space-between;

  .otp-ref {
    width: 100%;
  }

  .otp-form {
    width: 100%;

    .otp-action {
      display: flex;
      gap: 8px;
      align-items: center;
    }

    .otp-again-text {
      text-align: right;
      color: var(--primary-color);
    }
    .otp-again-button {
      color: var(--primary-color);
      width: fit-content;
      height: fit-content;
      font-size: 12px;
      padding: 0;
    }
  }

  .otp-form-success {
    color: #3c763d;
    width: 100%;
    display: flex;
    gap: 4px;
    align-items: center;
    justify-content: flex-end;

    svg {
      color: #3c763d;
    }
  }
}
::v-deep .modal-header {
  color: #fff;
  width: 100%;
  background-color: var(--primary-color);
}
::v-deep .modal-body {
  padding: 0.5rem 1rem;
}
.btn-primary .modal-body {
  margin: auto;
  text-align: center;
}
::v-deep .nav-tabs li:nth-child(2) {
  border-left: 1px solid white;
  border-right: 1px solid white;
}
.summary-transaction-panel {
  background-color: var(--secondary-color);
  padding: 10px;
  .row {
    margin-bottom: 5px;
  }
  .row:last-child {
    margin-bottom: 0px;
  }
}

::v-deep .div-input {
  .text-error {
    white-space: break-spaces;
    word-break: break-word;
  }
}

::v-deep .custom-input {
  input {
    text-align: end;
  }
}

@media (max-width: 510px) {
  // ::v-deep .otp-action {

  //   align-items: flex-end !important;

  //   .otp-again-button,
  //   .otp-again-text {
  //     order: 2;
  //   }
  // }
}

.add-payment-container {
  background-color: white !important;
  border: 2px dashed;
  border-radius: 4px;
  border-color: gray;
  cursor: pointer;
}

.add-payment-container:hover {
  color: white !important;
  border: 2px dashed;
  border-radius: 4px;
  border-color: var(--secondary-color);
  background-color: var(--secondary-color) !important;
  opacity: 0.6;
  transition: 0.3s;
  cursor: pointer;
}

::v-deep option:disabled {
  color: light-dark(graytext, rgb(208, 204, 204)) !important;
}
</style>
