<template>
  <b-modal
    size="md"
    centered
    title="ประวัติคะแนน"
    v-model="isShow"
    @hidden="clearValue()"
    @ok="addPoint"
    ok-title="Add Point"
    footer-class="content-between"
    hide-footer
  >
    <table class="w-100" id="point-table">
      <thead>
        <th class="text-center w-2 py-2">คะแนน</th>
        <th class="text-center w-2 py-2">วันหมดอายุ</th>
      </thead>
      <tbody>
        <template v-if="pointDetail.length > 0">
          <tr v-for="(items, index) of pointDetail" :key="items.id + '-point'">
            <td class="text-center w-2 py-2">
              <template v-if="isEdit && index == isIndexEdit">
                <InputText
                  textFloat=""
                  placeholder=""
                  class="mb-0"
                  type="number"
                  v-model="formEditPoint.point"
                  name="add-point"
                />
              </template>

              <template v-else>
                {{ items.point | numeral("0,0") }}
              </template>
            </td>
            <td class="text-center w-2 py-2">
              <div>
                <template v-if="isEdit && index == isIndexEdit">
                  <InputDatePickerFilter
                    style="margin-bottom: 0px !important"
                    textFloat=""
                    name="point-expire-date"
                    ref="point-expire-date"
                    :value="formEditPoint.expired_date"
                    @input="(val) => (formEditPoint.expired_date = val)"
                  />
                </template>
                <div v-else>
                  <span v-if="items.expired_date" class="ml-auto">
                    {{
                      $moment(items.expired_date).format("DD MMM YYYY")
                    }}</span
                  >
                  <span v-else class="ml-auto">-</span>
                </div>
              </div>
            </td>
          </tr>
        </template>
        <template v-else>
          <tr class="bg-light">
            <td colspan="3" class="text-center py-2">No data</td>
          </tr>
        </template>
      </tbody>
    </table>
  </b-modal>
</template>

<script>
export default {
  data() {
    return { isShow: false, pointDetail: [] };
  },
  methods: {
    show(user_guid) {
      console.log("object");
      this.isShow = true;
      this.getList(user_guid);
    },
    async getList(user_guid) {
      await this.axios
        .get(`${this.$baseUrl}/customer/GetAllPoint/${user_guid}`)
        .then(async (data) => {
          if (data.result == 1) {
            this.pointDetail = data.detail;
          }
        });
    },
    getPointLog() {},
    addPoint() {},
  },
};
</script>

<style></style>
