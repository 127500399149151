<template>
  <div>
    <b-modal
      v-model="showModal"
      ref="modal"
      centered
      size="xl"
      hide-footer
      no-close-on-backdrop
      footer-class="pt-0 border-0"
    >
      <template #modal-header="{ close }">
        <div class="d-flex justify-content-between align-items-center w-100">
          <div class="text-center flex-grow-1 text-r-20">สินค้า</div>
        </div>
        <button type="button" aria-label="Close" class="close" @click="close">
          ×
        </button>
      </template>

      <div class="bg-white border-red">
        <div class="d-block">
          <b-tabs v-model="tabIndex" class="product-tab">
            <b-tab :title="`ทั้งหมด (${all.rows})`">
              <div class="d-flex justify-content-start align-items-center">
                <InputText
                  v-model="all.filter.search"
                  textFloat=""
                  type="text"
                  name="search_product"
                  placeholder="ค้นหาสินค้า"
                  faIcon="search"
                  className="my-2"
                  :disabled="all.isBusy"
                  @onEnter="(val) => handleSearch(val, 'all')"
                  @onClickIcon="(val) => handleSearch(val, 'all')"
                />
                <div>
                  <img
                    src="@/assets/images/icons/bin.png"
                    alt="delete"
                    class="pointer delete-icon"
                    center
                    width="32"
                    @click="handleSearch('', 'all')"
                  />
                </div>
              </div>
              <b-row v-if="!isMobile">
                <b-col>
                  <b-table
                    responsive
                    striped
                    hover
                    :fields="fields"
                    :items="all.items"
                    :busy="all.isBusy"
                    show-empty
                    empty-text="No matching records found"
                    class="table-main oveflow-auto table-custom"
                    :no-border-collapse="false"
                  >
                    <template v-slot:cell(select)="data">
                      <b-button
                        variant="primary-color"
                        class="btn-modal btn-hover"
                        @click="selectRow(data.item, 'all')"
                        :disabled="all.isBusy || store_credit ? true : false"
                      >
                        เลือก
                      </b-button>
                    </template>
                    <template v-slot:cell(name)="data">
                      <b>{{ data.item.name || "-" }}</b>
                      <p>{{ data.item.category_name || "-" }}</p>
                    </template>
                    <template v-slot:cell(price)="data">
                      <div>
                        {{ data.item.price | numeral("0,0.00") }}
                      </div>
                    </template>
                    <template v-slot:table-busy>
                      <div class="text-center text-black my-2">
                        <b-spinner class="align-middle"></b-spinner>
                        <strong class="ml-2">Loading...</strong>
                      </div>
                    </template>
                  </b-table>
                </b-col>
              </b-row>
              <b-row v-else>
                <b-col>
                  <div class="py-2 detail-panel mb-5 mt-2">
                    <div
                      class="product-card"
                      v-for="(c, index) in all.items"
                      :key="index"
                      @click="selectRow(c, 'all')"
                    >
                      <div class="product-card-detail">
                        <div>
                          <strong>{{ c.barcode }}</strong>
                          <p class="text-general">{{ c.name }}</p>
                        </div>
                        <div>
                          <p>
                            {{ c.price | numeral("0,0.00") }}
                          </p>
                        </div>
                      </div>
                    </div>
                    <div class="no-data-item" v-if="all.items.length == 0">
                      ไม่พบข้อมูล
                    </div>
                  </div>
                </b-col>
              </b-row>
              <Pagination
                @handleChangeTake="(val) => handleChangeTake(val, 'all')"
                :pageOptions="pageOptions"
                :filter="all.filter"
                :rows="all.rows"
                @pagination="(val) => pagination(val, 'all')"
              />
            </b-tab>
            <b-tab :title="`สินค้า (${booth.rows})`" v-if="isBooth">
              <div class="d-flex justify-content-start align-items-center">
                <InputText
                  v-model="booth.filter.search"
                  placeholder="ค้นหาสินค้า"
                  type="text"
                  name="search_product"
                  faIcon="search"
                  className="my-2"
                  :disabled="booth.isBusy"
                  @onEnter="(val) => handleSearch(val, 'booth')"
                  @onClickIcon="(val) => handleSearch(val, 'booth')"
                />
                <div>
                  <img
                    src="@/assets/images/icons/bin.png"
                    alt="delete"
                    class="pointer delete-icon"
                    center
                    width="32"
                    @click="handleSearch('', 'booth')"
                  />
                </div>
              </div>
              <b-row v-if="!isMobile">
                <b-col>
                  <b-table
                    responsive
                    striped
                    hover
                    :fields="fields"
                    :items="booth.items"
                    :busy="booth.isBusy"
                    show-empty
                    empty-text="No matching records found"
                    class="table-main oveflow-auto table-custom"
                    :no-border-collapse="false"
                  >
                    <template v-slot:cell(select)="data">
                      <b-button
                        variant="primary-color"
                        class="btn-modal btn-hover"
                        @click="selectRow(data.item, 'booth')"
                        :disabled="booth.isBusy || store_credit ? true : false"
                      >
                        เลือก
                      </b-button>
                    </template>
                    <template v-slot:cell(name)="data">
                      <b>{{ data.item.name || "-" }}</b>
                      <p>{{ data.item.category_name || "-" }}</p>
                    </template>
                    <template v-slot:cell(price)="data">
                      <div>
                        {{ data.item.price | numeral("0,0.00") }}
                      </div>
                    </template>
                    <template v-slot:table-busy>
                      <div class="text-center text-black my-2">
                        <b-spinner class="align-middle"></b-spinner>
                        <strong class="ml-2">Loading...</strong>
                      </div>
                    </template>
                  </b-table>
                </b-col>
              </b-row>
              <b-row v-else>
                <b-col>
                  <div class="py-2 detail-panel mb-5 mt-2">
                    <div
                      class="product-card"
                      v-for="(c, index) in booth.items"
                      :key="index"
                      @click="selectRow(c, 'booth')"
                    >
                      <div class="product-card-detail">
                        <div>
                          <strong>{{ c.barcode }}</strong>
                          <p class="text-general">{{ c.name }}</p>
                        </div>
                        <div>
                          <p>
                            {{ c.price | numeral("0,0.00") }}
                          </p>
                        </div>
                      </div>
                    </div>
                    <div class="no-data-item" v-if="booth.items.length == 0">
                      ไม่พบข้อมูล
                    </div>
                  </div>
                </b-col>
              </b-row>
              <Pagination
                @handleChangeTake="(val) => handleChangeTake(val, 'booth')"
                :pageOptions="pageOptions"
                :filter="booth.filter"
                :rows="booth.rows"
                @pagination="(val) => pagination(val, 'booth')"
              />
            </b-tab>
            <!-- <b-tab :title="`บริการสปา (${spa.rows})`" v-if="isSpa">
              <div class="d-flex justify-content-start align-items-center">
                <InputText
                  v-model="spa.filter.search"
                  placeholder="ค้นหาบริการสปา"
                  type="text"
                  name="search_product"
                  faIcon="search"
                  className="my-2"
                  :disabled="spa.isBusy"
                  @onEnter="(val) => handleSearch(val, 'spa')"
                  @onClickIcon="(val) => handleSearch(val, 'spa')"
                />
                <div>
                  <img
                    src="@/assets/images/icons/bin.png"
                    alt="delete"
                    class="pointer delete-icon"
                    center
                    width="32"
                    @click="handleSearch('', 'spa')"
                  />
                </div>
              </div>
              <b-row v-if="!isMobile">
                <b-col>
                  <b-table
                    responsive
                    striped
                    hover
                    :fields="fields"
                    :items="spa.items"
                    :busy="spa.isBusy"
                    show-empty
                    empty-text="No matching records found"
                    class="table-main oveflow-auto table-custom"
                    :no-border-collapse="false"
                  >
                    <template v-slot:cell(select)="data">
                      <b-button
                        variant="primary-color"
                        class="btn-modal btn-hover"
                        @click="selectRow(data.item, 'spa')"
                        :disabled="spa.isBusy"
                      >
                        เลือก
                      </b-button>
                    </template>
                    <template v-slot:cell(name)="data">
                      <b>{{ data.item.name || "-" }}</b>
                      <p>{{ data.item.category_name || "-" }}</p>
                    </template>
                    <template v-slot:cell(price)="data">
                      <div>
                        {{ data.item.price | numeral("0,0.00") }}
                      </div>
                    </template>
                    <template v-slot:table-busy>
                      <div class="text-center text-black my-2">
                        <b-spinner class="align-middle"></b-spinner>
                        <strong class="ml-2">Loading...</strong>
                      </div>
                    </template>
                  </b-table>
                </b-col>
              </b-row>
              <b-row v-else>
                <b-col>
                  <div class="py-2 detail-panel mb-5 mt-2">
                    <div
                      class="product-card"
                      v-for="(c, index) in spa.items"
                      :key="index"
                      @click="selectRow(c, 'spa')"
                    >
                      <div class="product-card-detail">
                        <div>
                          <strong>{{ c.barcode }}</strong>
                          <p class="text-general">{{ c.name }}</p>
                        </div>
                        <div>
                          <p>
                            {{ c.price | numeral("0,0.00") }}
                          </p>
                        </div>
                      </div>
                    </div>
                    <div class="no-data-item" v-if="spa.items.length == 0">
                      ไม่พบข้อมูล
                    </div>
                  </div>
                </b-col>
              </b-row>
              <Pagination
                @handleChangeTake="(val) => handleChangeTake(val, 'spa')"
                :pageOptions="pageOptions"
                :filter="spa.filter"
                :rows="spa.rows"
                @pagination="(val) => pagination(val, 'spa')"
              />
            </b-tab> -->
            <b-tab :title="`เติมเครดิต`">
              <b-row class="h-100 credit-wrapper">
                <b-col class="py-3" sm="12" md="6">
                  <div class="border h-100">
                    <div
                      class="m-2 d-flex justify-content-between credit-panel align-items-center"
                    >
                      <p>เครดิตสะสม</p>
                      <div>
                        <span class="total">{{
                          credit.total_credit | numeral("0,0.00")
                        }}</span>
                        บาท
                      </div>
                    </div>
                    <b-row class="p-2">
                      <b-col>
                        <HeaderPanel
                          title="ประวัติย้อนหลัง"
                          :hasFilter="false"
                        />
                        <b-table
                          responsive
                          striped
                          hover
                          :fields="credit.fields"
                          :items="credit.items"
                          :busy="credit.isBusy"
                          show-empty
                          empty-text="No matching records found"
                          class="table-main table-credit mt-1"
                          :no-border-collapse="false"
                          sticky-header
                        >
                          <template v-slot:cell(credit_balance)="data">
                            <div>
                              {{ data.item.credit_balance | numeral("0,0.00") }}
                              /
                              {{ data.item.total_credit | numeral("0,0.00") }}
                            </div>
                          </template>
                          <template v-slot:cell(created_time)="data">
                            <div>
                              {{
                                new Date(data.item.created_time)
                                  | moment($formatDateNew)
                              }}
                            </div>
                          </template>
                          <template v-slot:cell(expired_date)="data">
                            <div>
                              {{
                                new Date(data.item.expired_date)
                                  | moment($formatDateNew)
                              }}
                            </div>
                          </template>
                          <template v-slot:cell(action)="data">
                            <font-awesome-icon
                              icon="edit"
                              class="edit"
                              @click="editExpiredDate(data.item)"
                            />
                          </template>
                          <template v-slot:table-busy>
                            <div class="text-center text-black my-2">
                              <b-spinner class="align-middle"></b-spinner>
                              <strong class="ml-2">Loading...</strong>
                            </div>
                          </template>
                        </b-table>
                      </b-col>
                    </b-row>
                  </div>
                </b-col>
                <b-col sm="12" md="6">
                  <div class="border my-3 p-3 h-230px">
                    <HeaderPanel
                      title="ทำรายการเติมวงเงินเครดิต"
                      :hasFilter="false"
                    />
                    <InputText
                      v-model="credit.form.net_price"
                      textFloat="วงเงินที่ต้องการเติม"
                      className="mb-2"
                      @onKeypress="isNumber($event)"
                    />
                    <!-- <InputText
                      v-model="credit.form.discount_price"
                      textFloat="วงเงินแถม"
                      className="mb-2"
                      @onKeypress="isNumber($event)"
                    />
                    <InputText
                      v-model="credit.form.total_price"
                      textFloat="วงเงินคงเหลือ"
                      className="mb-2"
                      disabled
                    /> -->
                    <InputDatePickerFilter
                      textFloat="วันหมดอายุใหม่"
                      name="expire"
                      class="custom-date-picker"
                      :disabled="store_credit"
                      v-model="credit.form.expired_date"
                      @input="inputExpiredDate"
                      :minDatetime="now"
                    />
                  </div>
                </b-col>
              </b-row>
              <div class="text-center">
                <b-button
                  variant="primary-color"
                  class="btn-modal btn-hove"
                  :disabled="isLoading"
                  @click="addCredit"
                >
                  ตกลง
                </b-button>
              </div>
            </b-tab>
          </b-tabs>
        </div>
      </div>
    </b-modal>
    <ModalNote ref="modalNote" :branchId="branchId" />
    <ModalEditExpiredDate ref="modalEditExpiredDate" @success="getCreditData" />
  </div>
</template>

<script>
import InputText from "@/components/inputs/InputText";
import OtherLoading from "@/components/loading/OtherLoading";
import Pagination from "@/components/Pagination";
import { mapGetters } from "vuex";
import ModalNote from "@/components/customer/ModalNote";
import HeaderPanel from "@/components/HeaderPanel";
import InputDatePickerFilter from "@/components/inputs/InputDatePickerFilter";
import ModalEditExpiredDate from "./ModalEditExpiredDate";

export default {
  components: {
    OtherLoading,
    Pagination,
    InputText,
    ModalNote,
    HeaderPanel,
    InputDatePickerFilter,
    ModalEditExpiredDate,
  },
  props: {
    isMobile: {
      type: Boolean,
      default: false,
    },
    store_credit: {
      default: false,
    },
  },
  data() {
    return {
      isLoading: false,
      showModal: false,
      all: {
        filter: {
          search: "",
          datetime: "",
          branchId: "",
          page: 1,
          take: 5,
        },
        items: [],
        isBusy: false,
        rows: 0,
      },
      booth: {
        filter: {
          search: "",
          datetime: "",
          branchId: "",
          page: 1,
          take: 5,
        },
        items: [],
        isBusy: false,
        rows: 0,
      },
      spa: {
        filter: {
          search: "",
          datetime: "",
          branchId: "",
          page: 1,
          take: 5,
        },
        items: [],
        isBusy: false,
        rows: 0,
      },
      credit: {
        fields: [
          {
            key: "created_time",
            label: "วันที่ซื้อ",
            thClass: "credit-column",
          },
          {
            key: "credit_balance",
            label: "คงเหลือ / จำนวนเครดิต (บาท)",
            thClass: "credit-column",
          },
          {
            key: "expired_date",
            label: "วันที่หมดอายุ",
            thClass: "credit-column",
          },
        ],
        form: {
          id: 1,
          net_price: 0,
          discount_price: 0,
          total_price: 0,
          expired_date: null,
        },
        filter: {
          search: "",
        },
        total_credit: 0,
        items: [],
        isBusy: false,
        rows: 0,
      },
      fields: [
        {
          key: "select",
          label: "",
        },
        {
          key: "barcode",
          label: "รหัสสินค้า",
        },
        {
          key: "name",
          label: "ชื่อสินค้า",
          tdClass: "text-left",
        },
        {
          key: "price",
          label: "ราคาสินค้า",
        },
      ],
      now: null,
      expireDate: null,
      pageOptions: [
        { value: 5, text: "5 / page" },
        { value: 10, text: "10 / page" },
        { value: 30, text: "30 / page" },
        { value: 50, text: "50 / page" },
        { value: 100, text: "100 / page" },
      ],

      tabIndex: 0,
      user: null,
    };
  },
  computed: {
    ...mapGetters({
      dateTimeFix: "getDateTimeFix",
      branchId: "getBranchId",
      isSpa: "getSpa",
      isBooth: "getBooth",
    }),
  },
  watch: {
    "credit.form.net_price"(newValue, oldValue) {
      this.credit.form.total_price =
        parseFloat(newValue) + parseFloat(this.credit.form.discount_price);
    },
    "credit.form.discount_price"(newValue, oldValue) {
      this.credit.form.total_price =
        parseFloat(newValue) + parseFloat(this.credit.form.net_price);
    },
    tabIndex(val) {
      if (val == 1) this.getSearchBooth();
      else if (val == 2) this.getSearchSpa();
      else if (val == 3) this.getCreditData();
    },
  },
  methods: {
    isNumber: function (evt) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if (
        charCode > 31 &&
        (charCode < 48 || charCode > 57) &&
        charCode !== 46
      ) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
    async searchBeforeShow(user, search) {
      this.all.isBusy = true;
      this.now = this.$moment().format("YYYY-MM-DDTHH:mm:ss");

      this.user = user;
      this.tabIndex = 0;
      this.all.filter.search = search;
      this.credit.form = {
        id: 1,
        net_price: 0,
        discount_price: 0,
        total_price: 0,
        expired_date: this.$moment()
          .add(2, "years")
          .format("YYYY-MM-DDTHH:mm:ss"),
      };
      this.all.filter.page = 1;
      await this.getSearchAll();
      await this.getCreditData();
      const items = this.all.items;
      this.all.items = [];
      this.all.isBusy = false;
      return items;
    },
    async show(user, search) {
      await this.$store.dispatch("setMainLoading", true);
      this.now = this.$moment().format("YYYY-MM-DDTHH:mm:ss");

      this.user = user;
      this.tabIndex = 0;
      this.all.filter.search = search;
      if (this.store_credit) {
        this.credit.form = {
          id: 1,
          net_price: this.store_credit.net_price,
          discount_price: 0,
          total_price: this.store_credit.total_price,
          expired_date: this.store_credit.expired_date,
        };
      } else {
        this.credit.form = {
          id: 1,
          net_price: 0,
          discount_price: 0,
          total_price: 0,
          expired_date: this.$moment()
            .add(2, "years")
            .format("YYYY-MM-DDTHH:mm:ss"),
        };
      }
      this.all.filter.page = 1;
      await this.getSearchAll();
      this.booth.filter.page = 1;

      this.spa.filter.page = 1;

      await this.getCreditData();
      await this.$store.dispatch("setMainLoading", false);
      this.showModal = true;
    },
    hide() {
      this.showModal = false;
    },
    pagination(page, key) {
      this[key].filter.page = page;
      this.searchByTab();
    },
    handleChangeTake(value, key) {
      this[key].filter.page = 1;
      this[key].filter.take = value;
      this.searchByTab();
    },
    handleSearch(search, key) {
      this[key].filter.page = 1;
      this[key].filter.search = search;
      this.searchByTab();
    },
    searchByTab() {
      if (this.tabIndex == 0 && !this.all.isBusy) {
        this.getSearchAll();
      } else if (this.tabIndex == 1 && !this.booth.isBusy) {
        this.getSearchBooth();
      } else if (this.tabIndex == 2 && !this.spa.isBusy) {
        this.getSearchSpa();
      }
    },
    async selectRow(item, key) {
      await this.setSelectProduct(item, key);
      await this.hide();
    },
    setSelectProduct(item, key) {
      this.$emit("setSelectProduct", item, key);
    },
    async getCreditData() {
      await this.axios
        .get(
          `${this.$baseUrl}/product/get_store_creditbalance/${this.user.user_guid}`
        )
        .then((data) => {
          this.credit.items = data.credit;
          this.credit.rows = this.credit.items.length;
          this.credit.total_credit = data.total_credit;
        });
    },
    async getSearchAll() {
      this.all.isBusy = true;
      let body = {
        articleType: "",
        ah2_id: null,
        ah3_id: null,
        ah4_id: null,
        search: this.all.filter.search,
        skip: (this.all.filter.page - 1) * this.all.filter.take,
        take: this.all.filter.take,
        spa: this.isSpa,
        booth: this.isBooth,
        branch_id: this.branchId,
      };
      await this.axios
        .post(`${this.$baseUrl}/product/search_product`, body)
        .then((data) => {
          if (data.result == 1) {
            this.all.items = data.detail;
            this.all.rows = data.total_count;
            this.all.isBusy = false;
          }
        });
    },
    async getSearchBooth() {
      this.booth.isBusy = true;
      let body = {
        articleType: "ZFIN",
        ah2_id: null,
        ah3_id: null,
        ah4_id: null,
        search: this.booth.filter.search,
        skip: (this.booth.filter.page - 1) * this.booth.filter.take,
        take: this.booth.filter.take,
        spa: this.isSpa,
        booth: this.isBooth,
        branch_id: this.branchId,
      };
      if (this.isBooth) {
        await this.axios
          .post(`${this.$baseUrl}/product/search_product`, body)
          .then((data) => {
            if (data.result == 1) {
              this.booth.items = data.detail;
              this.booth.rows = data.total_count;
              this.booth.isBusy = false;
            }
          });
      }
    },
    async getSearchSpa() {
      this.spa.isBusy = true;
      let body = {
        articleType: "ZPACK",
        ah2_id: null,
        ah3_id: null,
        ah4_id: null,
        search: this.spa.filter.search,
        skip: (this.spa.filter.page - 1) * this.spa.filter.take,
        take: this.spa.filter.take,
        spa: this.isSpa,
        booth: this.isBooth,
        branch_id: this.branchId,
      };
      if (this.isSpa) {
        await this.axios
          .post(`${this.$baseUrl}/product/search_product`, body)
          .then((data) => {
            if (data.result == 1) {
              this.spa.isBusy = false;
              this.spa.items = data.detail;
              this.spa.rows = data.total_count;
            }
          });
      }
    },
    async addCredit() {
      await this.$emit("addCredit", this.credit.form);
      await this.hide();
    },
    inputExpiredDate(val) {
      this.credit.form.expired_date = val;
    },
    editExpiredDate(item) {
      this.$refs.modalEditExpiredDate.show(item);
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .modal-header {
  color: #fff;
  width: 100%;
  background-color: var(--primary-color);
}

::v-deep .modal-body {
  padding: 1rem 1rem;
}

.btn-primary .modal-body {
  margin: auto;
  text-align: center;
}

::v-deep .nav-tabs li {
  border-left: 1px solid white;
  border-right: 1px solid white;
}

.credit-panel {
  background-color: var(--secondary-color);
  padding: 10px 10px;
  font-size: 14px;
  font-weight: bold;

  .total {
    font-size: 20px;
  }
}

::v-deep .table-credit {
  .table.b-table > thead > tr > th {
    padding-left: 4px !important;
    padding-right: 4px !important;
  }
}

::v-deep .table-custom {
  thead {
    position: sticky;
    top: 0;
  }

  height: 336px;
}

.delete-icon {
  margin-left: 8px;
  // margin-bottom: 13px;
}

.product-tab {
  ::v-deep &.tabs > div:not(.tab-content) {
    overflow: auto;

    .nav-tabs {
      width: max-content;
    }
  }
}

.product-card {
  min-height: 88px;
  cursor: pointer;
  color: #333333;
  background-color: #fff;
  padding: 0.5rem;
  margin: 0.5rem;

  .product-card-detail {
    height: 100%;
    display: flex;
    justify-content: space-between;
  }
}

.no-data-item {
  min-height: 88px;
  display: flex;
  justify-content: center;
  font-size: 14px;
  color: #bdbdbd;
  align-items: center;
  background-color: #fff;
  padding: 0.5rem;
  margin: 0.5rem;
}

.custom-date-picker {
}

@media (max-width: 768px) {
  ::v-deep .div-input {
    width: 100%;
  }
  ::v-deep .modal-dialog {
    margin: 0px;
    max-width: 100vw !important;
    .modal-content {
      width: 100vw;
      height: 100vh;
      .modal-body {
        overflow-y: auto;
      }
    }
  }
}

.h-230px {
  height: 230px;
}
</style>
